.col_light{
	color:#fff;
}
.col_tonic{
	color:#ebce45;
}

.col_blue{
	color:#418fde;
}
.col_red{
	color:#c21c37;
}



/*-- characters --*/
.col_ben{
	color:#b03131;
}
.col_joseph{
	color:#418f71;
}
.col_alice{
	color:#dba818;
}




.col_grey{
	color:#a4a8ad;	
}

.col_dark{
	color:#000;
}
.col_danger{
	color:#B00;
}
.col_warning{
	color:#fa0;
}
.col_valid{
	color:#0a0;
}


.col_D68424{
	color:#D68424;
}
