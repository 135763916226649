html, body{
	width:100%; height:100%;
}

body{
	margin:0;
	overflow:hidden;
}
p{
	margin-block-start: 0;
	margin-block-end: 0;
	margin-bottom:0; margin-top:0;
}

app-root{
	width:100%; height:100%;
	display:block;
	overflow:hidden;
	background-color:black;
}