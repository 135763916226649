.anim_right{
	position:relative;
}
.anim_left{
	position:relative;
}
.anim_bottom, .anim_bottom_delay{
	position:relative;
}
.anim_top{
	position:relative;
}



.anim_fade{
	transition: opacity 0.3s;
	transition-timing-function: ease-out;
}


.btn_anim{
	&:active{
		// transform:scale(0.97);
		transform:scale(1.03);
	}
}

.btn_anim_small{
	&:active{
		// transform:scale(0.84);
		//transform:scale(0.5);
		font-size: 20px;
	}
}
