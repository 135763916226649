@import 'library/responsive/media-queries.scss';

.rotatedevice{
	
	height: 100%; width:100%;
	position:fixed;
	background-color:#1a425f;
	display:flex;
	align-items: center;
	justify-content:center;
	
	
	& > div{
		display:flex;
		flex-direction: column;
		align-items: center;
	}
	
	.rotatedevice_icon{
	
		//background-image: url("../assets/images/ui/turn01.png");
		// @include retinaBg('../assets/images/ui/turn01', 321px, 400px);
		background-repeat: no-repeat;
		width:321px; height:400px;
		// background-color:#F3C72D;
	}
	.rotatedevice_text{
		margin-top:50px;
		font-size:48px;
		color:white;
	}
}


@media only screen and (orientation:portrait){
	.rotatedevice { display:flex; }
}
@media only screen and (orientation:landscape){
	.rotatedevice { display:none; }
}