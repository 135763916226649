/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2018 */
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Homemade+Apple&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pangolin&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid&display=swap');
@import url("https://use.typekit.net/ssz6crf.css");

@font-face {
	font-family:"lores-9-plus-wide";
	src:url("https://use.typekit.net/af/6eeb5a/00000000000000007735bf84/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6eeb5a/00000000000000007735bf84/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6eeb5a/00000000000000007735bf84/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
	font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
    font-family: 'Cafeta';
    src: url('cafeta__-webfont.woff2') format('woff2'),
         url('cafeta__-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'NexaLight';
    src: url('nexa_light-webfont.woff2') format('woff2'),
         url('nexa_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'NexaBold';
    src: url('nexa_bold-webfont.woff2') format('woff2'),
         url('nexa_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}


@font-face {
	font-family: 'GothamRounded-Bold';
	src: /* url('nexa_bold-webfont.woff2') format('woff2'), */
			 url('GothamRnd-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}


@font-face {
	font-family: 'GothamRounded-Book';
	src: url('GothamRnd-Book.eot');
	src: local('☺'), 
		url('GothamRnd-Book.woff') format('woff'), 
		url('GothamRnd-Book.ttf') format('truetype'), 
		url('GothamRnd-Book.svg') format('svg');
		font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'GothamRounded-Medium';
	src: url('GothamRnd-Book.eot');
	src: local('☺'), 
		url('GothamRnd-Book.woff') format('woff'), 
		url('GothamRnd-Book.ttf') format('truetype'), 
		url('GothamRnd-Book.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'NexaBold';
    src: url('nexa_bold-webfont.woff2') format('woff2'),
         url('nexa_bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
	font-family: 'Josefin Sans';
	src: url('JosefinSans-SemiBold.eot');
	src: local('☺'), 
		url('JosefinSans-SemiBold.woff') format('woff'), 
		url('JosefinSans-SemiBold.ttf') format('truetype'), 
		url('JosefinSans-SemiBold.svg') format('svg');
		font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Josefin Sans';
	src: url('JosefinSans-SemiBoldItalic.eot');
	src: local('☺'), 
		url('JosefinSans-SemiBoldItalic.woff') format('woff'), 
		url('JosefinSans-SemiBoldItalic.ttf') format('truetype'), 
		url('JosefinSans-SemiBoldItalic.svg') format('svg');
		font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Josefin Sans';
	src: url('JosefinSans-Bold.eot');
	src: local('☺'), 
		url('JosefinSans-Bold.woff') format('woff'), 
		url('JosefinSans-Bold.ttf') format('truetype'), 
		url('JosefinSans-Bold.svg') format('svg');
		font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SkaterGirlsRock';
	src: local('☺'), 
		url('SkaterGirlsRock.woff') format('woff'), 
		url('SkaterGirlsRock.ttf') format('truetype'), 
		url('SkaterGirlsRock.woff2') format('woff2');
		font-weight: normal;
	font-style: normal;
}
