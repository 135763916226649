@import 'library/responsive/media-queries';
@import 'animations';

/*__________________________________________________________*/
/* generic */



.btn{
	cursor:pointer;
	user-select: none;
}

textarea:focus, input:focus, button:focus{
	outline: none;
}

.clickable{
	cursor:pointer;
}


/*__________________________________________________________*/


a.btn_link{
	@extend .btn;
	
	display:inline-block;
	@extend .nexalight_13;
	@extend .col_grey;
	@extend .text_left;
	
	&:active{
		@extend .col_light;
	}
}

a.btn_small{
	@extend .btn;
	@extend .btn_anim_small;
}

a.btn_back{
	@extend .btn;
	@extend .gothamrounded_bold_21;
	@extend .col_light;
	@extend .btn_anim_small;
	background-image:url('^assets/images/ui/btns/icon_back.png');
	//// @include retinaBg('^assets/images/ui/btns/icon_back', 21px, 29px);
	background-repeat:no-repeat;
	
	padding-left:35px;
	padding-top:2px;
	text-align:left;
	
}

a.btn_icon_right{
	@extend .btn;
	@extend .gothamrounded_bold_21;
	@extend .col_light;
	@extend .btn_anim_small;
	
	display:flex;
	align-items:center;
	& > img{
		margin-left:10px;
	}
}






/*__________________________________________________________*/
/* inputs */

input.regular{
	
	width:350px - 2 * 10px; height:30px;
	border:0;
	background-color: transparent;
	background-image:url('^assets/images/ui/btns/bg_input.png');
	// @include retinaBg('^assets/images/ui/btns/bg_input', 351px, 30px);
	
	padding:0px 10px;
	
	@extend .col_dark;
	@extend .nexalight_16;
	@extend .text_left;
	
	&::placeholder{
		@extend .col_dark;
		@extend .nexalight_16;
		@extend .text_left;
	}
	&:not(:first-child) {
		margin-top:13px;
	}
	
	
}








input[type=checkbox]:checked + label{
	background-image:url('^assets/images/ui/inputs/checkbox-checked.png');
	//// @include retinaBg('^assets/images/ui/inputs/checkbox_on', 35px, 31px);
	background-repeat: no-repeat;
} 
input[type=checkbox] + label{
	background-image:url('^assets/images/ui/inputs/checkbox.png');
	//// @include retinaBg('^assets/images/ui/inputs/checkbox_off', 31px, 31px);
	background-repeat: no-repeat;
	padding-left:38px;
	padding-top:5px;
	padding-bottom:7px;
	cursor:pointer;
}

