.glow{
	text-shadow: 0 0 8px #4cd4fb;
}
.glow_white{
	text-shadow: 0 0 5px #ffffff;
}

.glow_title{
	text-shadow: 0 0 4px #ebce45;
}
.glow_red{
	text-shadow: 0 0 4px #eb4568;
}


.glow_desc{
	text-shadow: 0 0 9px #3dbef4;
}
