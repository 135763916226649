.scaled_container{
	position:absolute;
	width:1200px; height:675px;
	top:50%; left:50%;
	overflow:hidden;
}

.background{
	// background-size: cover;
	// width:100%; height:100%;
	// position:fixed;
	// background-position: 50% 100%; 
	width:1200px; height:675px;
	position:absolute;
	left:0px; top:0px;
	
}

.cache_modal{
	position:absolute;
	width:100%; height:100%;
	background-color:#001122;
	opacity:0.95;
}


.center_container{
	position:absolute;
	left:50%; top:50%;
	transform: translate(-50%, -50%);
	// overflow:hidden;
}

.container_bottom{
	position:absolute;
	left:50%; top:100%;
	transform: translate(-50%, -100%);
	// overflow:hidden;
}

.container{
	display:flex;
	flex-direction: column;
	align-items: center;
}

.modal_container{
	background-color:#16222c;
	opacity:0.9;
	border-radius: 25px;
	padding:35px 70px;
	padding-bottom:66px;
}




/*__________________________________________________________*/
/* application */




.linesep{
	background-image:url('^assets/images/pages/common/linesep.png');
	//// @include retinaBg('^assets/images/pages/common/linesep', 364px, 14px);
	width:364px; height:14px;
}

.logo{
	background-image:url('^assets/images/pages/home/logo.png');
	//// @include retinaBg('^assets/images/pages/home/logo', 435px, 376px);
	width:435px; height:435px;
}





