.text_left{
	text-align:left;
}
.text_right{
	text-align:right;
}
.text_center{
	text-align:center;
}
.text_justify{
	text-align:justify;
}