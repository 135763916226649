.cafeta_32{
	font-family:'Cafeta';
	font-size:32px;
	letter-spacing:0px;
}

.gothamrounded_bold_21{
	font-family:'GothamRounded-Bold';
	font-size:21px;
	letter-spacing:0px;
}
.cafeta_25{
	font-family:'Cafeta';
	font-size:25px;
	letter-spacing:0px;
}
.nexalight_16{
	font-family:'NexaLight';
	font-size:16px;
	letter-spacing:0px;
}
.nexalight_13{
	font-family:'NexaLight';
	font-size:13px;
	letter-spacing:0px;
	text-decoration: underline;
}

.cafeta_27{
	font-family:'Cafeta';
	font-size:27px;
	letter-spacing:0px;
}
.cafeta_36{
	font-family:'Cafeta';
	font-size:36px;
	letter-spacing:0px;
}
.cafeta_36_percent{
	font-family:'Cafeta';
	font-size:36px;
	letter-spacing:3px;
}
.cafeta_17{
	font-family:'Cafeta';
	font-size:17px;
	letter-spacing:0px;
}
.cafeta_22{
	font-family:'Cafeta';
	font-size:22px;
	letter-spacing:0px;
}


.cafeta_52{
	font-family:'Cafeta';
	font-size:52px;
	letter-spacing:0px;
}
.chicago_19{
	font-family:'Chicago';
	font-size:19px;
	letter-spacing:0px;
}
.cafeta_26{
	font-family:'Cafeta';
	font-size:26px;
	letter-spacing:-1px;
	line-height:23px;
}
.gothamrounded_book_17{
	font-family:'GothamRounded-Book';
	font-size:17px;
	letter-spacing:0px;
}
.nexabold_18{
	font-family:'NexaBold';
	font-size:18px;
	letter-spacing:0px;
}
.nexabold_25{
	font-family:'NexaBold';
	font-size:25px;
	letter-spacing:0px;
}
.nexabold_40{
	font-family:'NexaBold';
	font-size:40px;
	letter-spacing:0px;
}
.nexabold_68{
	font-family:'NexaBold';
	font-size:68px;
	letter-spacing:0px;
}



.cafeta_73{
	font-family:'Cafeta';
	font-size:73px;
	letter-spacing:0px;
}
.cafeta_80{
	font-family:'Cafeta';
	font-size:80px;
	letter-spacing:0px;
}
.gothamrounded_medium_17{
	font-family:'GothamRounded-Medium';
	font-size:17px;
	letter-spacing:0px;
}
.chicago_21{
	font-family:'NexaBold';
	font-size:21px;
	letter-spacing:0px;
}
.cafeta_29{
	font-family:'Cafeta';
	font-size:29px;
	letter-spacing:0px;
}
.cafeta_42{
	font-family:'Cafeta';
	font-size:42px;
	letter-spacing:0px;
}
.cafeta_38{
	font-family:'Cafeta';
	font-size:38px;
	letter-spacing:0px;
}
.gothamrounded_book_19{
	font-family:'GothamRounded-Book';
	font-size:19px;
	letter-spacing:0px;
}
.gothamrounded_medium_21{
	font-family:'GothamRounded-Medium';
	font-size:21px;
	letter-spacing:0px;
}
.gothamrounded_medium_19{
	font-family:'GothamRounded-Medium';
	font-size:19px;
	letter-spacing:0px;
}

.nexabold_15{
	font-family:'NexaBold';
	font-size:15px;
	letter-spacing:0px;
}
.nexabold_22{
	font-family:'NexaBold';
	font-size:22px;
	letter-spacing:0px;
}
.nexabold_19{
	font-family:'NexaBold';
	font-size:19px;
	letter-spacing:0px;
}
.cafeta_31{
	font-family:'Cafeta';
	font-size:31px;
	letter-spacing:0px;
}
.nexabold_17{
	font-family:'NexaBold';
	font-size:17px;
	letter-spacing:0px;
}

.gothamrounded_book_14{
	font-family:'GothamRounded-Book';
	font-size:14px;
	letter-spacing:0px;
}
.cafeta_21{
	font-family:'Cafeta';
	font-size:21px;
	letter-spacing:0px;
}


.gothamrounded_bookitalic_19{
	font-family:'GothamRounded-Book';
	font-size:19px;
	letter-spacing:0px;
	font-style:italic;
}

.nexabold_21{
	font-family:'NexaBold';
	font-size:21px;
	letter-spacing:0px;
}

.nexabold_69{
	font-family:'NexaBold';
	font-size:69px;
	letter-spacing:0px;
}

.cafeta_24{
	font-family:'Cafeta';
	font-size:24px;
	letter-spacing:0px;
}
.gothamrounded_book_15{
	font-family:'GothamRounded-Book';
	font-size:15px;
	letter-spacing:0px;
}




